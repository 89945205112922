import {INamedEntity} from 'Common/models/INamedEntity';
import {AnimalType} from '../../Common/constants/AnimalType';

export enum ProductStatus {
  Disabled = 'Disabled',
  Active = 'Active',
  Archived = 'Archived',
}

export interface IProductSimple extends INamedEntity {
  status?: ProductStatus;
  animalType?: AnimalType;
}
