import React, {memo, useCallback, useEffect, useState} from 'react';

import {Form, Formik, FormikProps, useFormik, withFormik} from 'formik';
import {validationSchema, IFormValues} from './validation';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {ErrorMessage, MutableField} from 'Common/components/StyledComponents/StyledComponents';

import styled from 'styled-components';

import ModalWindow from '../../../../Common/components/Modal/ModalWindow';

import {castToOption, getStringKeysOption} from '../../../../Common/helpers/OptionHelper';
import {SelectOwner} from '../../../../HorseProfile/components/HorseProfileForm/parts';
import {DogGender, Gender} from '../../../../Common/constants/Gender';
import {useDogUpdate} from '../../helpers/hooks/useDogActions/useDogUpdate';
import {IDog} from '../../../../Dogs/models/IDog';
import {dogBreeds} from 'Common/constants/DogBreeds';
import {dogDisciplines} from 'Common/constants/DogDisciplines';
import withDate from 'Common/helpers/withDate';
import {FRONTEND_DATE} from 'Common/constants/Date';
import moment from 'moment';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

interface ExternalProps {
  dog: IDog;
  onSuccess(): void;
}

type OuterProps = ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

export default function DogEditForm(props: AllProps) {
  const {onSuccess} = props;

  const updateDog = useDogUpdate();
  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (props.dog.dateOfBirth) {
    const frontendDate = moment(props.dog.dateOfBirth).format(FRONTEND_DATE);
    if (frontendDate) {
      props.dog.dateOfBirth = frontendDate.toString();
    }
  }

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>Edit Dog</ModalWindowHeader>

      {/*{ <Loading />}*/}
      <Formik
        initialValues={props.dog}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          await updateDog({
            id: props.dog.id,
            ownerId: props.dog.owner.id,
            name: values.name,
            breed: values.breed,
            gender: values.gender,
            dateOfBirth: values.dateOfBirth ? new Date(values.dateOfBirth).toISOString() : undefined,
            discipline: values.discipline,
            sampleId: values.sampleId,
          });
          setIsSubmitting(false);
          onSuccess();
        }}
      >
        <Form className="d-flex flex-column justify-content-center">
          <ModalWindowFormContent>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <InputField
                  isRequired={true}
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Dogs name"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
              <MutableField width={50}>
                <InputField
                  isRequired={false}
                  name="sampleId"
                  type="text"
                  label="Sample ID"
                  placeholder="Dogs sample ID"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
            </div>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <DateTimeField isRequired={false} name="dateOfBirth" label="Date of birth" memoized={true} />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isRequired={true}
                  name="gender"
                  label="Sex"
                  options={getStringKeysOption(DogGender)}
                  memoized={true}
                />
              </MutableField>
            </div>
            <div className="d-flex" style={{gap: '8px'}}>
              <MutableField width={50}>
                <SelectField
                  isMulti={false}
                  name="breed"
                  label="Breeds"
                  options={getStringKeysOption(dogBreeds)}
                  memoized={false}
                  isRequired={true}
                />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isMulti={false}
                  name="discipline"
                  label="Disciplines"
                  options={getStringKeysOption(dogDisciplines)}
                  memoized={false}
                  isRequired={false}
                />
              </MutableField>
            </div>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Save
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </Formik>
    </>
  );
}
