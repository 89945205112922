import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {
  IActivationCodeForm,
  IActivationCodeRequest,
} from 'Admin/AdminDashboard/components/ActivationCodes/ActivationCodeForm/validation';

export function convertActivationCodeToActivationCodeForm(activationCode: IActivationCode): IActivationCodeForm {
  return {
    ...activationCode,
    productId: activationCode.productId,
    product: activationCode.product,
    animalType: activationCode.animalType,
    amount: 1,
  };
}

export function convertActivationCodeFormToActivationCodeRequest(
  activationCode: IActivationCodeForm
): IActivationCodeRequest {
  return {
    ...activationCode,
    // ensure that all productIds are strings
    productId: activationCode.productId.toString(),
    animalType: activationCode.animalType,
    amount: activationCode.amount ?? 0,
  };
}
