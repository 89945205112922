import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {FormikBag, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import TagManager from 'react-gtm-module';
import * as R from 'ramda';

import Typography from 'Common/constants/Typography';
import {actions, selectors} from 'Order/store/createOrder';
import {CreateOrderModule} from 'Order/store/createOrder/createOrderModule';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';
import {convertHorseTestToServer} from 'Order/services/converters/order';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {StripeForm} from 'Payment/components';
import {ICreatedOrder} from 'Order/models/ICreatedOrder';
import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {getCommonErrors, getErrorCode, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {CouponError} from 'Order/services/constants/CouponError';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {useToast} from 'Common/helpers/hooks/useToast';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {SummaryBackground, SummaryContainer} from 'Order/shared/StyledComponents';

import {
  convertErrors,
  convertFormValuesToRequest,
  FormErrors,
  getCouponError,
  IFormValues,
  initialValues,
  validationSchema,
} from './validation';
import Summary, {PayNowActions} from '../Summary';
import {OrderPaymentModule} from 'Payment/store/orderPayment/orderPaymentModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import CreateOrderMainContent, {CreateOrderMainContentProps} from 'Order/components/CreateOrder/CreateOrderMainContent';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {ShoppingCartModule} from 'Order/store/shoppingCart/shoppingCartModule';
import {actions as shoppingCartActions, selectors as shoppingCartSelectors} from 'Order/store/shoppingCart';
import {IHorsePackageRequest} from 'Order/services/types/shoppingCart';
import {IHorseTest} from 'Order/models/IHorseTest';
import {convertTestsToIHorsePayment} from 'Order/helpers/convertTestsToIHorseTestRequest';

const CREATE_ORDER_ERROR = 'Unknown error on creating order';
const CONFIRM_ORDER_PLACED = 'Please confirm you wish to complete this order';

const Root = styled.div`
  background-color: ${ColorPalette.gray49};
  @media ${breakpoints.md} {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
`;

const Header = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 24px 16px;
  overflow: hidden;

  @media ${breakpoints.sm} {
    padding: 57px 40px 24px 40px;
  }

  @media ${breakpoints.md} {
    width: 65%;
    padding: 60px 16px 24px 72px;
  }
`;

interface IExternalProps {
  packageGroupsDictionary: ISimpleDictionary<IPackageGroup>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function CreateOrder(props: AllProps) {
  const {values, isValid, setFieldTouched, isSubmitting, submitCount, setFieldValue, setErrors, setStatus, submitForm} =
    props;
  const {
    isLoadingFormData,
    getHorses,
    getSummary,
    horses,
    summary,
    coupon,
    getCoupon,
    creatingOrder,
    createdOrder,
    summaryLoading,
    couponLoading,
    removeCoupon,
    removeSummary,
    getContactInformation,
    updateContactInformation,
    contactInformationLoading,
    contactInformationUpdating,
    getOrderPackages,
    orderPackages,
    orderPackagesLoading,
    packageGroupsDictionary,
    packageGroups,
    packageGroupsLoading,
  } = props;
  const {
    shoppingCart,
    getShoppingCart,
    shoppingCartLoading,
    clearShoppingCart,
    createShoppingCart,
    deleteShoppingCart,
  } = props;

  const {
    actions: {getItems: getPackageGroups},
  } = packageGroupsDictionary;

  const {addToast} = useToast();
  const navigate = useNavigate();

  const [isReviewOrder, setIsReviewOrder] = useState(false);
  const [isOpenStripePaymentForm, setIsOpenStripePaymentForm] = useState(false);
  const [couponError, setCouponError] = useState<string | null>(null);
  const createdOrderRef = useRef<ICreatedOrder | null>();
  const [isPayPalWorking, setIsPayPalWorking] = useState(false);
  const [isShoppingCartLoaded, setIsShoppingCartLoaded] = useState(false);
  const [openedCards, setOpenedCards] = useState<number[]>([]);

  const redirectToResultPage = useCallback(() => {
    const token = createdOrderRef.current?.token || createdOrder?.token;
    navigate(`/order-placed/${token}`);
  }, [createdOrder?.token, navigate]);

  const redirectToSuccessPayment = useCallback(() => {
    if (createdOrder && createdOrder.id && createdOrder.amount !== 0) {
      console.log('Event: Purchase');
      console.log('Order Id: ' + createdOrder.id);
      console.log('Order Total: ' + createdOrder.amount / 100);
      // Google Tag Manager tracker
      TagManager.dataLayer({
        dataLayer: {
          event: 'Purchase',
          ecommerce: {
            transaction_id: createdOrder.id,
            value: createdOrder.amount,
            currency: 'USD',
          },
        },
      });
    }

    navigate(`/order-placed/${createdOrder?.token}/payment`);
  }, [navigate, createdOrder?.token]);

  const isNeedToPay = values.paymentMethod !== PaymentMethod.Invoice && createdOrder?.amount !== 0;
  useOnSuccessCommunication(creatingOrder, () => {
    if (isNeedToPay) {
      return;
    }

    if (createdOrder?.amount === 0) {
      redirectToSuccessPayment();
      return;
    }

    redirectToResultPage();
  });

  const openStripePaymentForm = useCallback(() => {
    setIsOpenStripePaymentForm(true);
  }, []);

  const closeStripePaymentForm = useCallback(() => {
    setIsOpenStripePaymentForm(false);
  }, []);

  const checkCode = useCallback(
    (code: string) => {
      getCoupon({code, horses: convertHorseTestToServer(values.tests)});
    },
    [values.tests, getCoupon]
  );

  const applyCode = useCallback(() => {
    if (!values.couponCode) {
      return;
    }
    checkCode(values.couponCode);
  }, [values.couponCode, checkCode]);

  const removeCouponFromOrder = useCallback(() => {
    setFieldValue('couponCode', '');
    removeCoupon();
    setCouponError(null);
  }, [removeCoupon, setFieldValue]);

  useEffect(() => {
    getHorses();
    getPackageGroups();
    getContactInformation();
    getOrderPackages();
    scrollToTop();
  }, [getHorses, getContactInformation, getOrderPackages, getPackageGroups]);

  useEffect(() => {
    const fetch = async () => {
      setIsShoppingCartLoaded(false);
      await getShoppingCart();
      setIsShoppingCartLoaded(true);
    };
    fetch();
  }, [getShoppingCart]);

  const onAddToShoppingCartItem = useCallback(
    async (items: IHorsePackageRequest[]) => {
      try {
        for (const item of items) {
          await createShoppingCart(item);
        }
      } catch (err) {
      } finally {
        setIsShoppingCartLoaded(true);
      }
    },
    [createShoppingCart]
  );

  const onDdeleteFromShoppingCartItem = useCallback(
    async (items: IHorsePackageRequest[]) => {
      setIsShoppingCartLoaded(false);
      try {
        for (const item of items) {
          await deleteShoppingCart(item);
        }
      } catch (err) {
      } finally {
        setIsShoppingCartLoaded(true);
      }
    },
    [deleteShoppingCart]
  );

  /*
  Interaction with Shopping cart.
  Adding or Deleting items is defined on differences by values.tests and ShoppingCart in store.
  */
  useEffect(() => {
    if (!isShoppingCartLoaded) {
      return;
    }

    const notEmpty = R.compose(R.not, R.isEmpty);

    const flattenTests = values.tests.flatMap((t) =>
      t.horses.map((h) => {
        if (h !== 0) {
          return {packageId: t.packageId, horseId: h};
        } else return {};
      })
    );

    const flattenShoppingCart = shoppingCart.flatMap((sc) =>
      sc.horses.map((h) => ({packageId: sc.packageId, horseId: h}))
    );

    const notEmptyFlattenTests = R.filter(notEmpty, flattenTests).map((x) => ({
      horseId: x.horseId!,
      packageId: x.packageId!,
    }));

    const itemsToAdd = R.difference(notEmptyFlattenTests, flattenShoppingCart);
    const itemsToRemove = R.difference(flattenShoppingCart, notEmptyFlattenTests);

    onAddToShoppingCartItem(itemsToAdd);
    onDdeleteFromShoppingCartItem(itemsToRemove);
  }, [isShoppingCartLoaded, onAddToShoppingCartItem, onDdeleteFromShoppingCartItem, shoppingCart, values.tests]);

  const isTestEmpty =
    values.tests.length === 0 ||
    values.tests.every((x) => !x.horses) ||
    values.tests.every((x) => x.horses.length === 0) ||
    values.tests.every((x) => x.horses.every((y) => y === 0));

  useEffect(() => {
    if (coupon && !isTestEmpty) {
      checkCode(coupon.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.tests, checkCode, isTestEmpty]);

  useEffect(() => {
    if (isTestEmpty) {
      removeSummary();
      return;
    }

    getSummary({
      horses: convertHorseTestToServer(values.tests),
      couponId: coupon?.id,
    });
  }, [values.tests, coupon, getSummary, isTestEmpty, removeSummary]);

  useEffect(() => {
    createdOrderRef.current = createdOrder;
  }, [createdOrder]);

  const creatingOrderError = creatingOrder.error;
  const creatingOrderFieldErrors = useMemo(() => {
    return getFieldErrors<undefined, FormErrors>(creatingOrderError, convertErrors);
  }, [creatingOrderError]);

  const creatingOrderCommonErrors = useMemo(() => {
    return getCommonErrors(creatingOrderError);
  }, [creatingOrderError]);

  const unexpectedCreatingOrderError = useMemo(() => {
    if (creatingOrder.error && !creatingOrderCommonErrors && !creatingOrderFieldErrors) {
      setIsOpenStripePaymentForm(false);
      return CREATE_ORDER_ERROR;
    }
    return '';
  }, [creatingOrder.error, creatingOrderCommonErrors, creatingOrderFieldErrors]);

  const onCreatingOrderError = useCallback(() => {
    if (creatingOrderFieldErrors) {
      setErrors(creatingOrderFieldErrors);
    }

    if (creatingOrderCommonErrors) {
      setStatus(creatingOrderCommonErrors);
      addToast(creatingOrderCommonErrors, 'error');
    }
  }, [setErrors, setStatus, addToast, creatingOrderFieldErrors, creatingOrderCommonErrors]);

  useOnErrorCommunication(creatingOrder, onCreatingOrderError);

  const summaryError = useMemo(() => {
    if (couponLoading.isRequesting && summaryLoading.error && !couponError) {
      setIsOpenStripePaymentForm(false);
      return getCommonErrors(summaryLoading.error) || CREATE_ORDER_ERROR;
    }
    return undefined;
  }, [summaryLoading.error, couponLoading.isRequesting, couponError]);

  const onCouponError = useCallback(() => {
    if (couponLoading.error) {
      const couponErrorText = getCouponError(couponLoading.error);
      addToast(couponErrorText, 'error');
      setCouponError(couponErrorText);
    }
    if (coupon && couponLoading.error && getErrorCode(couponLoading.error) === CouponError.CannotBeAppliedCoupon) {
      return;
    }
    removeCoupon();
  }, [coupon, couponLoading.error, removeCoupon, addToast]);

  useOnErrorCommunication(couponLoading, onCouponError);
  useOnSuccessCommunication(couponLoading, () => setCouponError(null));

  const [isConfirmPlacedModalOpen, setIsConfirmPlacedModalOpen] = useState(false);

  const openConfirmPlacedOrderModal = useCallback(() => {
    setIsConfirmPlacedModalOpen(true);
  }, []);

  const closeConfirmPlacedOrderModal = useCallback(() => {
    setIsConfirmPlacedModalOpen(false);
  }, []);

  const onSuccessConfirmPlacedOrderModal = useCallback(() => {
    submitForm();
    setIsConfirmPlacedModalOpen(false);
  }, [submitForm]);

  const onCreateOrder = useCallback(async () => {
    if (values.paymentMethod === PaymentMethod.PayPal) {
      setIsPayPalWorking(true);
    }
    if (!createdOrder) {
      await submitForm();
    }
  }, [values.paymentMethod, createdOrder, submitForm]);

  const onPayPalSuccess = useCallback(
    async (details: any, data: any) => {
      redirectToSuccessPayment();
    },
    [redirectToSuccessPayment]
  );

  const onPayPalCancel = useCallback(() => {
    setIsPayPalWorking(false);
    redirectToResultPage();
  }, [redirectToResultPage]);

  const onPayPalError = useCallback(() => {
    setIsPayPalWorking(false);
  }, []);

  const orderPayPalToken = useMemo(() => {
    if (!createdOrder) {
      return '';
    }
    return createdOrder.token;
  }, [createdOrder]);

  const hasCouponError = !!coupon && !!couponError;
  const disablePlaceOrder =
    (summary?.packagesPrice?.packages?.length || 0) < 1 ||
    !(submitCount === 0 || isValid) ||
    hasCouponError ||
    !isValid;

  const isLoading =
    isLoadingFormData ||
    contactInformationLoading.isRequesting ||
    contactInformationUpdating.isRequesting ||
    orderPackagesLoading.isRequesting ||
    packageGroupsLoading.isRequesting ||
    shoppingCartLoading.isRequesting ||
    isSubmitting ||
    isPayPalWorking;
  const isSummaryLoading = summaryLoading.isRequesting || couponLoading.isRequesting;

  const payNowActions: PayNowActions = {
    Stripe: openStripePaymentForm,
  };

  const openPayButtons = useCallback(() => {
    console.log('Event: InitiateCheckout');
    // Google Tag Manager tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'InitiateCheckout',
      },
    });
    setIsReviewOrder(true);
  }, []);

  const editClick = useCallback(() => {
    setIsReviewOrder(false);
  }, []);

  const updateContactInformationHandler = useCallback(async () => {
    (props.touched.address || props.touched.phoneNumber) &&
      !!values.address &&
      (await updateContactInformation({address: values.address, phoneNumber: values.phoneNumber}));
    setFieldTouched('address', false);
    setFieldTouched('phoneNumber', false);
  }, [
    props.touched.address,
    props.touched.phoneNumber,
    setFieldTouched,
    updateContactInformation,
    values.address,
    values.phoneNumber,
  ]);

  const addTestsField = useCallback(
    (packageId: number) => {
      const valuesTests = [...values.tests];
      const findPackageId = valuesTests.findIndex((x) => x.packageId === packageId);
      const findPackageId2 = openedCards.findIndex((x) => x === packageId);

      if (findPackageId === -1) {
        const newTest: IHorseTest = {packageId, horses: [0]};
        setFieldValue('tests', valuesTests.concat(newTest));
      }

      if (findPackageId2 === -1) {
        setOpenedCards([...openedCards, packageId]);
      }
    },
    [openedCards, setFieldValue, values.tests]
  );

  const removeTestField = useCallback(
    async (packageId: number, isRemoveEmptyOnly?: boolean) => {
      const valuesTests = [...values.tests];
      const findPackage = valuesTests.find((x) => x.packageId === packageId);

      if (
        (findPackage && !isRemoveEmptyOnly) ||
        (findPackage && isRemoveEmptyOnly && findPackage.horses.length === 0)
      ) {
        const findPackageId = valuesTests.findIndex((x) => x.packageId === packageId);

        valuesTests.splice(findPackageId, 1);
        await setFieldValue('tests', valuesTests);

        // values.tests is not updated without setFieldTouched('tests', true)
        // https://github.com/jaredpalmer/formik/issues/2059
        setFieldTouched('tests', true);
      }
    },
    [setFieldTouched, setFieldValue, values.tests]
  );

  const removeEmptyTestField = useCallback(
    async (packageId: number) => {
      removeTestField(packageId, true);

      const newOpenedCards = [...openedCards];
      const findOpenedCard = newOpenedCards.findIndex((x) => x === packageId);
      if (findOpenedCard !== -1) {
        newOpenedCards.splice(findOpenedCard, 1);
        setOpenedCards(newOpenedCards);
      }
    },
    [openedCards, removeTestField]
  );

  const handleCloseClick = useCallback(
    async (packageId: number, updatedHorses: number[]) => {
      const testValuesClone = [...values.tests];
      const findPackageIndex = testValuesClone.findIndex((x) => x.packageId === packageId);
      if (findPackageIndex !== -1) {
        if (updatedHorses.length > 0) {
          testValuesClone[findPackageIndex].horses = updatedHorses;
        } else {
          testValuesClone.splice(findPackageIndex, 1);
        }
        await setFieldValue('tests', testValuesClone);
        setFieldTouched('tests', true);
        const newOpenedCards = [...openedCards];
        const findOpenedCard = newOpenedCards.findIndex((x) => x === packageId);
        if (findOpenedCard !== -1) {
          newOpenedCards.splice(findOpenedCard, 1);
          setOpenedCards(newOpenedCards);
        }
      }
    },
    [setFieldValue, setFieldTouched, values.tests, openedCards]
  );

  const horsesSummary = useMemo(() => convertTestsToIHorsePayment(values.tests, horses), [horses, values.tests]);

  const onClearShoppingCart = useCallback(async () => {
    setIsShoppingCartLoaded(false);
    setFieldValue('tests', initialValues.tests);
    await clearShoppingCart();
    setIsShoppingCartLoaded(true);
  }, [clearShoppingCart, setFieldValue]);

  const mainContentProps: CreateOrderMainContentProps = {
    testSelectionProps: {
      isValid: values.tests !== initialValues.tests && !props.errors.tests,
      horses,
      orderPackages,
      tests: values.tests,
      packageGroups: packageGroups,
      onEditClick: editClick,
      onOpenCard: addTestsField,
      onSuccess: removeEmptyTestField,
      onClearShoppingCart: onClearShoppingCart,
      onCloseCard: handleCloseClick,
    },
    discountProps: {
      isValid: true,
      coupon,
      onApplyCode: applyCode,
      onDeleteCoupon: removeCouponFromOrder,
      isDisabled: !values.couponCode,
      isLoading: couponLoading.isRequesting,
      onEditClick: editClick,
    },
    paymentProps: {isValid: true, type: values.paymentMethod, onContinueClick: openPayButtons, onEditClick: editClick},
    contactInformationProps: {
      isValid:
        values.address !== initialValues.address &&
        values.phoneNumber !== initialValues.phoneNumber &&
        !props.errors.address &&
        !props.errors.phoneNumber,
      userInfo: {phoneNumber: values.phoneNumber, address: values.address},
      onClose: updateContactInformationHandler,
      onContinueClick: updateContactInformationHandler,
      onEditClick: editClick,
    },
  };

  return (
    <BaseLayout backButtonStyle={{marginLeft: 70, marginTop: 20}} withoutPaddings={true}>
      <Helmet>
        <title>Order tests</title>
      </Helmet>
      <ModalWindow isOpen={isOpenStripePaymentForm} onClose={closeStripePaymentForm} maxWidth="500px">
        <StripeForm
          token={createdOrder?.token}
          amount={summary?.total || 0}
          onSuccess={redirectToSuccessPayment}
          createOrder={onCreateOrder}
          creatingOrder={creatingOrder.isRequesting}
        />
      </ModalWindow>

      <WarningModalWindow
        modalType={ModalTypes.Confirm}
        isOpen={isConfirmPlacedModalOpen}
        onClose={closeConfirmPlacedOrderModal}
        onSuccess={onSuccessConfirmPlacedOrderModal}
        successButtonText="Confirm"
        isSuccessLoading={creatingOrder.isRequesting}
      >
        {CONFIRM_ORDER_PLACED}
      </WarningModalWindow>

      <Root>
        {isLoading && <Loading />}
        <FormContainer>
          <Header>Order tests</Header>
          <CreateOrderMainContent {...mainContentProps} />
        </FormContainer>

        <SummaryContainer>
          <SummaryBackground />
          <Summary
            isDisablePlaceOrder={disablePlaceOrder}
            packagesPrice={!isTestEmpty ? summary?.packagesPrice : undefined}
            couponsAmountSum={summary?.couponDiscount || 0}
            total={summary?.total || 0}
            gift={summary?.giftCardPayment || 0}
            error={unexpectedCreatingOrderError || summaryError}
            paymentMethod={values.paymentMethod}
            isSummaryLoading={isSummaryLoading}
            onPayNow={payNowActions}
            onPayLater={openConfirmPlacedOrderModal}
            onPayPalSuccess={onPayPalSuccess}
            onPayPalClick={onCreateOrder}
            onPayPalCancel={onPayPalCancel}
            onPayPalError={onPayPalError}
            token={orderPayPalToken}
            isReviewOrder={isReviewOrder}
            horsesSummary={horsesSummary}
            onClearPackage={removeTestField}
            openedCards={openedCards}
          />
        </SummaryContainer>
      </Root>
    </BaseLayout>
  );
}

const formikPropsToValues = ({contactInformation, shoppingCart}: OuterProps): IFormValues => {
  return {
    ...initialValues,
    tests: shoppingCart, // Restoring from saved Shopping Cart
    address: contactInformation?.address || initialValues.address,
    phoneNumber: contactInformation?.phoneNumber || initialValues.phoneNumber,
  };
};

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  try {
    formikBag.setSubmitting(true);
    const {createOrder, coupon} = formikBag.props;
    await createOrder(convertFormValuesToRequest(values, coupon?.id));
  } finally {
    formikBag.setSubmitting(false);
  }
};

const CreateOrderWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: formikPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
})(memo(CreateOrder));

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {packageGroupsDictionary} = externalProps;
  const {selectors: packageGroupSelectors} = packageGroupsDictionary;

  return {
    isLoadingFormData: selectors.selectIsLoadingFormData(state),
    horses: selectors.selectOrderHorses(state),
    summary: selectors.selectSummary(state),
    coupon: selectors.selectCoupon(state),
    createdOrder: selectors.selectCreatedOrder(state),
    contactInformation: selectors.selectContactInformation(state),
    creatingOrder: selectors.selectCommunication(state, 'creatingOrder'),
    summaryLoading: selectors.selectCommunication(state, 'summaryLoading'),
    couponLoading: selectors.selectCommunication(state, 'couponLoading'),
    contactInformationLoading: selectors.selectCommunication(state, 'contactInformationLoading'),
    contactInformationUpdating: selectors.selectCommunication(state, 'contactInformationUpdating'),
    orderPackages: selectors.selectOrderPackages(state),
    orderPackagesLoading: selectors.selectCommunication(state, 'orderPackagesLoading'),
    packageGroups: packageGroupSelectors.selectItems(state),
    packageGroupsLoading: packageGroupSelectors.selectCommunication(state, 'itemsLoading'),
    shoppingCart: shoppingCartSelectors.selectShoppingCart(state),
    shoppingCartLoading: shoppingCartSelectors.selectCommunication(state, 'shoppingCartLoading'),
  };
};

const mapDispatchToProps = {
  getHorses: actions.getHorses,
  getSummary: actions.getSummary,
  getCoupon: actions.getCoupon,
  createOrder: actions.createOrder,
  removeCoupon: actions.resetCoupon,
  removeSummary: actions.resetSummary,
  getContactInformation: actions.getContactInformation,
  updateContactInformation: actions.updateContactInformation,
  getOrderPackages: actions.getOrderPackages,
  getShoppingCart: shoppingCartActions.getShoppingCart,
  clearShoppingCart: shoppingCartActions.clearShoppingCart,
  createShoppingCart: shoppingCartActions.createShoppingCart,
  deleteShoppingCart: shoppingCartActions.deleteShoppingCart,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(CreateOrderWithFormik);
const DynamicConnected = withDynamicModules(Connected, [CreateOrderModule, OrderPaymentModule, ShoppingCartModule]);

const Exported = (externalProps: Omit<IExternalProps, 'packageGroupsDictionary'>) => {
  const {packageGroups} = useDictionaries();
  return <DynamicConnected {...externalProps} packageGroupsDictionary={packageGroups} />;
};

export default Exported;
