import React, {memo, useCallback, useMemo, useState} from 'react';
import styled, {css} from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {withCurrency} from 'Common/helpers/withCurrency';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {ITestSummaryPackagesPrice} from 'Order/models/ITestSummary';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {PaymentMethod, PaymentMethodAdditional} from 'Common/constants/PaymentMethod';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import PayPalButton from 'Payment/components/PayPal/PayPalButton';
import {IHorsePayment} from 'Payment/models/IOrderSummary';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';

const NO_TEST_SELECTED = 'You haven’t selected any tests yet';

const marginStyle = css`
  margin: 0 16px;
  @media ${breakpoints.sm} {
    margin: 0 24px;
  }
  @media ${breakpoints.md} {
    margin: 0 40px;
  }
`;

const Root = styled.div`
  font-family: ${Typography.family.openSans};
  width: 100%;

  @media ${breakpoints.sm} {
    padding: 0 152px;
  }

  @media ${breakpoints.md} {
    margin-top: 60px;
    padding: 0;
  }
`;

const ExpandableZone = styled.div<{isShow: boolean}>`
  ${(prop) =>
    prop.isShow
      ? `max-height: 4000px; transition: opacity 0.2s 0.05s, max-height 0.2s;`
      : `max-height: 0; overflow: hidden; opacity: 0; transition: opacity 0.4s, max-height 0.2s; `}

  @media ${breakpoints.md} {
    max-height: 4000px;
    overflow: auto;
    opacity: 1;
  }
`;

const BlockWrapping = styled.div`
  padding-bottom: 18px;

  ${marginStyle}
`;

const HeaderText = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size20};
  color: ${Theme.color.black};
  font-weight: ${Typography.weight.semiBold600};
  line-height: 32px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }
`;

const HeaderMark = styled.div`
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${Theme.color.primary};
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  color: ${Theme.color.white};
  line-height: 16px;
  text-align: center;

  @media ${breakpoints.sm} {
    min-width: 32px;
    height: 32px;
    font-size: ${Typography.size.size24};
    line-height: 32px;
  }
`;

const FullWidthBlock = styled.div`
  flex-shrink: 10; /* need for safari */
  background-color: ${ColorPalette.white9};
  max-height: 50vh;
  overflow: auto;
  padding: 8px 16px 16px;
  margin-bottom: 8px;

  @media ${breakpoints.sm} {
    padding: 16px 24px 16px;
    margin-bottom: 18px;
  }
  @media ${breakpoints.md} {
    padding: 16px 40px 16px;
    margin-bottom: 18px;
  }
`;

const GroupHeader = styled.div`
  font-size: ${Typography.size.size16};
  line-height: 32px;
  color: ${ColorPalette.black1};
  font-family: ${Theme.font.primary};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size20};
  }
`;

const GroupValue = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 32px;
  color: ${ColorPalette.black1};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size20};
  }
`;

const Package = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-left: 16px;
  @media ${breakpoints.sm} {
    padding-left: 0;
  }
`;

const Item = styled.div`
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size14};
  font-weight: ${Typography.weight.normal400};
  line-height: 16px;
  letter-spacing: 0.2px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size16};
    line-height: 24px;
    letter-spacing: 0;
  }
`;

const ItemName = styled(Item)`
  color: ${ColorPalette.black3};
`;

const ItemValue = styled(Item)`
  color: ${ColorPalette.black3};
`;

const Total = styled.div`
  padding-top: 9px;
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  line-height: 32px;
  font-size: ${Typography.size.size20};
  color: ${Theme.color.black};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }

  ${marginStyle}
`;

const TotalValue = styled.div`
  ::before {
    content: ': ';
    @media ${breakpoints.md} {
      content: '';
    }
  }
`;

const ActionButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 6px;

  @media ${breakpoints.md} {
    margin-top: 16px;
  }
`;

const Divider = styled.div`
  border: none;
  height: 1px;
  background-color: ${ColorPalette.gray2};

  margin: 0 16px 16px;
  @media ${breakpoints.sm} {
    margin: 0 24px 16px;
  }
  @media ${breakpoints.md} {
    margin: 0 32px 16px;
  }
`;

const NoTestsMessage = styled.div`
  margin-bottom: 16px;
  padding: 0 32px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  letter-spacing: -0.4px;
  color: ${ColorPalette.gray1};
  text-align: center;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size23};
  }
`;

const Error = styled(BlockWrapping)`
  color: ${ColorPalette.red0};
  font-size: ${Typography.size.size18};
  font-family: ${Theme.font.primary};
`;

const PaymentMethodItem = styled(Item)`
  color: ${Theme.color.black};
`;

const ShowSummaryButton = styled(Item)`
  color: ${Theme.color.primary};
  letter-spacing: -0.2px;
  text-align: right;
  cursor: pointer;

  @media ${breakpoints.md} {
    display: none;
  }
`;

const PayPalButtonWrapper = styled.div`
  height: 110px;
  margin-top: 16px;
`;

const Horses = styled(Item)`
  color: ${ColorPalette.black4};
`;

const RemoveButton = styled(IconButton)`
  top: 2px;
  left: -8px;

  @media ${breakpoints.md} {
    top: 5px;
    left: -26px;
  }
`;

export type PayNowActions = Partial<Record<PaymentMethod | PaymentMethodAdditional, () => void>>;

interface IProps {
  packagesPrice?: ITestSummaryPackagesPrice;
  couponsAmountSum: number;
  gift: number;
  total: number;
  error?: string;
  isDisablePlaceOrder?: boolean;
  paymentMethod: PaymentMethod | PaymentMethodAdditional;
  isSummaryLoading: boolean;
  onPayNow?: PayNowActions;
  token?: string;
  onPayLater?: () => void;
  onPayPalSuccess?(details: any, data: any): void;
  onPayPalClick?(): void;
  onPayPalCancel?(): void;
  onPayPalError?(): void;
  isReviewOrder?: boolean;
  horsesSummary?: IHorsePayment[];
  onClearPackage?(packageId: number): void;
  openedCards?: number[];
}

function Summary(props: IProps) {
  const {
    packagesPrice,
    couponsAmountSum,
    gift,
    total,
    isDisablePlaceOrder,
    error,
    paymentMethod,
    isSummaryLoading,
    onPayNow,
    onPayLater,
    onPayPalSuccess,
    onPayPalClick,
    onPayPalCancel,
    onPayPalError,
    token,
    isReviewOrder,
    horsesSummary,
    onClearPackage,
    openedCards,
  } = props;
  const isTestsSelected = packagesPrice && packagesPrice.packages.length > 0;
  const allPackagesAtOrder = packagesPrice ? packagesPrice.packages.reduce((acc, curr) => acc + curr.count, 0) : 0;

  const getPaymentMethodValue = useMemo(() => {
    if (paymentMethod === PaymentMethod.Stripe) {
      return 'Credit card';
    }
    if (paymentMethod === PaymentMethodAdditional.PayPalCard) {
      return 'PayPal';
    }
    return paymentMethod;
  }, [paymentMethod]);
  const paymentMethodValue = getPaymentMethodValue;
  const {isDesktop} = useMediaQuery();

  const [isShowDetails, setIsShowDetails] = useState(isDesktop);

  const toggleShowDetails = useCallback(() => {
    setIsShowDetails(!isShowDetails);
  }, [isShowDetails]);

  const isPayNowDisabled = isDisablePlaceOrder || paymentMethod === PaymentMethod.Invoice || total === 0;
  const isPayNowShow = paymentMethod === PaymentMethod.Stripe && total > 0;
  const isPayPalShow = paymentMethod === PaymentMethod.PayPal && total > 0;
  const isInvoice = paymentMethod === PaymentMethod.Invoice && total > 0;
  // isSumZero for cases when user applied coupon for 100% or Package with 0$ and Total is 0, but we should place the order
  const isSumZero =
    (couponsAmountSum && total === 0) ||
    (total === 0 && packagesPrice?.total === 0 && packagesPrice?.packages.length > 0);
  const isPayLater = isSumZero || (onPayLater && isInvoice);

  const renderRemoveButton = useCallback(
    (packageId: number) => {
      return (
        <RemoveButton
          className="position-absolute"
          onClick={() => onClearPackage && onClearPackage(packageId)}
          name={IconName.Clear}
          color={Theme.color.primary}
          size={14}
          fill={true}
          stroke={false}
          hoverColor={Theme.color.control.active}
        />
      );
    },
    [onClearPackage]
  );

  return (
    <Root className="d-flex flex-column">
      <ExpandableZone isShow={isShowDetails}>
        <BlockWrapping className="d-flex align-items-center justify-content-between">
          <HeaderText>Tests summary</HeaderText>
          <HeaderMark className="d-flex align-items-center justify-content-center">{allPackagesAtOrder}</HeaderMark>
        </BlockWrapping>
        <Divider />
        {packagesPrice && isTestsSelected ? (
          <>
            <FullWidthBlock>
              <div className="d-flex align-items-center justify-content-between">
                <GroupHeader>Packages price</GroupHeader>
                <GroupValue>{withCurrency(packagesPrice.total)}</GroupValue>
              </div>
              {packagesPrice.packages.map((item, index) => {
                const horseNames = (packageId: number) => {
                  if (!horsesSummary) {
                    return '';
                  }

                  const names = horsesSummary
                    ?.filter((x) => x.packages.includes(packageId))
                    .map((x) => x.name)
                    .filter(Boolean)
                    .sort((a, b) => {
                      return a.localeCompare(b);
                    })
                    .join(', ');

                  return names;
                };

                const isClearEnable = onClearPackage && !openedCards?.includes(item.id);

                return (
                  <Package key={index} className="d-flex align-items-center justify-content-between position-relative">
                    <ItemName>
                      {item.name} {item.count > 1 && `x${item.count}`}
                      <Horses>{horseNames(item.id)}</Horses>
                    </ItemName>
                    <ItemValue className="align-self-start">{withCurrency(item.price)}</ItemValue>
                    {isClearEnable && renderRemoveButton(item.id)}
                  </Package>
                );
              })}
            </FullWidthBlock>

            {!!couponsAmountSum && (
              <FullWidthBlock className="d-flex align-items-center justify-content-between">
                <GroupHeader>Coupon Discount</GroupHeader>
                <GroupValue>{withCurrency(couponsAmountSum)}</GroupValue>
              </FullWidthBlock>
            )}

            {!!gift && (
              <FullWidthBlock className="d-flex align-items-center justify-content-between">
                <GroupHeader>Gift Card Payment</GroupHeader>
                <GroupValue>{withCurrency(gift)}</GroupValue>
              </FullWidthBlock>
            )}

            <BlockWrapping>
              <PaymentMethodItem className="d-flex align-items-center justify-content-between">
                <div>Payment method</div>
                <div>{paymentMethodValue}</div>
              </PaymentMethodItem>
            </BlockWrapping>
            <Divider style={{marginBottom: 0}} />
          </>
        ) : (
          <NoTestsMessage>{NO_TEST_SELECTED}</NoTestsMessage>
        )}
      </ExpandableZone>

      <Total className="d-flex align-items-center justify-content-between">
        <div>Total</div>
        <TotalValue>{withCurrency(total)}</TotalValue>
        <ShowSummaryButton className="flex-grow-1" onClick={toggleShowDetails}>
          {isShowDetails ? 'Hide summary' : 'Show summary'}
        </ShowSummaryButton>
      </Total>
      {isReviewOrder && (
        <BlockWrapping>
          {onPayNow && isPayNowShow && (
            <ActionButton onClick={onPayNow[paymentMethod]} disabled={isPayNowDisabled} isLoading={isSummaryLoading}>
              Pay now
            </ActionButton>
          )}

          {onPayNow && isPayPalShow && (
            <PayPalButtonWrapper>
              <PayPalButton
                onSuccess={onPayPalSuccess}
                token={token}
                onClick={onPayPalClick}
                onCancel={onPayPalCancel}
                onError={onPayPalError}
                disabled={isPayNowDisabled}
              />
            </PayPalButtonWrapper>
          )}

          {isPayLater && (
            <ActionButton
              variant="contained"
              onClick={onPayLater}
              disabled={isDisablePlaceOrder}
              isLoading={isSummaryLoading}
            >
              Place order
            </ActionButton>
          )}
        </BlockWrapping>
      )}
      <BlockWrapping style={{display: error ? 'flex' : 'none'}}>
        <Error>{error}</Error>
      </BlockWrapping>
    </Root>
  );
}

export default memo(Summary);
