import * as Yup from 'yup';

import {ActivationCodeStatus} from 'ActivationCode/constants/ActivationCodeStatus';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {IProductSimple, ProductStatus} from 'Dictionaries/models/IProductSimple';
import {AnimalType} from 'Common/constants/AnimalType';

export interface IActivationCodeProductDetails {
  productId: string;
  animalType: AnimalType;
  amount: number;
}

export interface IActivationCodeCreateRequest {
  productsDetails: IActivationCodeProductDetails[];
  source: string;
}

export interface IActivationCodeRequest extends IActivationCode {
  productId: string;
  animalType: AnimalType;
  amount?: number;
}

export interface IActivationCodeForm extends IActivationCode {
  productId: string;
  animalType: AnimalType;
  amount?: number;
}

export const initialValue: IActivationCodeForm = {
  id: 0,
  code: '',
  productId: '0',
  animalType: AnimalType.Horse,
  amount: 0,
  product: {id: 0, name: '', status: ProductStatus.Disabled},
  status: ActivationCodeStatus.Inactive,
  source: '',
  createdAt: null,
};

export const validationSchema = Yup.object().shape<IActivationCodeForm>({
  id: Yup.number(),
  code: Yup.string(),
  productId: Yup.string(),
  animalType: Yup.mixed<AnimalType>(),
  amount: Yup.number(),
  product: Yup.mixed<IProductSimple>(),
  status: Yup.mixed<ActivationCodeStatus>(),
  source: Yup.string(),
  createdAt: Yup.string().nullable(),
});
