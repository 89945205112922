import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import {getApiByVisitorType} from 'Common/helpers/getApiByVisitorType';
import {VisitorType} from 'Common/constants/VisitorType';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryPrivacy} from 'OnlineReport/models/Ancestry/IAncestryPrivacy';
import {convertAncestryKinshipHorseToClient, convertAncestryRelatedHorseToClient} from './converters/ancestry';
import {IAncestryPcaReferenceDataDto} from 'Common/models/IPcaReferenceDataDto';
import {IAncestryPcaReferencePoint} from 'OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';
import {convertPcaReferenceDataDtoToPcaReferencePoints} from '../../Admin/AdminDashboard/services/converters/ancestry';

const getPopulation = async (horseId: number): Promise<IAncestryPopulation[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryBreeds`,
    [VisitorType.AssociationEmployee]: `/AncestryBreedsAssociation`,
    [VisitorType.Admin]: `/AncestryBreedsAdmin`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryPopulation>>(`${api}/${horseId}`);
  return result.data.data;
};

const getPopulationComparison = async (horseId: number): Promise<IAncestryPopulationComparison> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryPcaPoint`,
    [VisitorType.AssociationEmployee]: `/AncestryPcaPointAssociation`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryPopulationComparison>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryProbabilities = async (horseId: number): Promise<IAncestryProbabilities> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryProbabilities`,
    [VisitorType.AssociationEmployee]: `/AncestryProbabilitiesAssociation`,
    [VisitorType.Admin]: `/AncestryProbabilitiesAdmin`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryProbabilities>>(`${api}/${horseId}`);
  return result.data.data;
};

const getAncestryRelatedHorses = async (horseId: number): Promise<IAncestryRelatedHorse[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryRelatedUser`,
    [VisitorType.AssociationEmployee]: `/AncestryRelatedAssociation`,
    [VisitorType.Admin]: `/AncestryRelatedAdmin`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryRelatedHorse>>(`${api}/${horseId}`);
  return result.data.data.map(convertAncestryRelatedHorseToClient);
};

const getAncestryKinshipHorses = async (horseId: number): Promise<IAncestryKinshipHorse[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryKinshipUser`,
    [VisitorType.AssociationEmployee]: `/AncestryKinshipAssociation`,
    [VisitorType.Admin]: `/AncestryKinshipAdmin`,
  });

  const result = await axiosWrapper.get<IListResponse<IAncestryKinshipHorse>>(`${api}/${horseId}`);
  return result.data.data.map(convertAncestryKinshipHorseToClient);
};

const getAncestryHorsePrivacy = async (privateId: string): Promise<IAncestryPrivacy> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryPrivacyValidateUser`,
    [VisitorType.AssociationEmployee]: `/AncestryPrivacyValidateEmployee`,
    [VisitorType.Admin]: `/AncestryPrivacyValidateAdmin`,
  });

  const result = await axiosWrapper.get<IObjectResponse<IAncestryPrivacy>>(api, {params: {privateId}});
  return result.data.data;
};

const getAncestryPcaReferenceData = async (): Promise<IAncestryPcaReferencePoint[]> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/AncestryPcaReferencePoint`,
    [VisitorType.AssociationEmployee]: `/AncestryPcaReferencePointAssociation`,
    [VisitorType.Admin]: `/AncestryPcaReferencePointAdmin`,
  });
  const result = await axiosWrapper.get<IListResponse<IAncestryPcaReferenceDataDto>>(api);
  return result.data.data.map(convertPcaReferenceDataDtoToPcaReferencePoints);
};

const sendHorsePublicityRequest = async (privateId: string, message: string): Promise<void> => {
  const api = getApiByVisitorType({
    [VisitorType.User]: `/HorsePublicityRequest`,
    [VisitorType.AssociationEmployee]: `/HorsePublicityRequestAssociation`,
    [VisitorType.Admin]: `/HorsePublicityRequestAdmin`,
  });

  const result = await axiosWrapper.post<IObjectResponse<void>>(api, {privateId, message});
  return result.data.data;
};

export default {
  getPopulation,
  getPopulationComparison,
  getAncestryProbabilities,
  getAncestryRelatedHorses,
  getAncestryKinshipHorses,
  getAncestryHorsePrivacy,
  getAncestryPcaReferenceData,
  sendHorsePublicityRequest,
};
