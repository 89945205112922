import {useEffect, useState} from 'react';

import {size} from 'Common/constants/Breakpoints';
import {ScreenSize} from 'Common/constants/ScreenSize';

function getCurrentSize(isMobile: boolean, isTablet: boolean, isDesktop: boolean): ScreenSize {
  if (isDesktop) {
    return 'desktop';
  }
  if (isTablet) {
    return 'tablet';
  }
  return 'mobile';
}

export const useMatchMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const useMediaQuery = () => {
  const mobileQuery = `(max-width: ${size.sm - 1}px)`;
  const tabletQuery = `(min-width: ${size.sm}px) and (max-width: ${size.md - 1}px)`;
  const desktopQuery = `(min-width: ${size.md}px)`;
  const lgDesktopQuery = `(min-width: ${size.md2}px)`;
  const xlgDesktopQuery = `(min-width: ${size.lg}px)`;

  const isMobile = useMatchMediaQuery(mobileQuery);
  const isTablet = useMatchMediaQuery(tabletQuery);
  const isDesktop = useMatchMediaQuery(desktopQuery);
  const isLargeDesktop = useMatchMediaQuery(lgDesktopQuery);
  const isExtraLargeDesktop = useMatchMediaQuery(xlgDesktopQuery);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isExtraLargeDesktop,
    currentSize: getCurrentSize(isMobile, isTablet, isDesktop || isLargeDesktop || isExtraLargeDesktop),
  };
};
