import React, {memo, useCallback, useEffect, useState} from 'react';

import {Form, Formik, FormikProps, useFormik, withFormik} from 'formik';
import {IFormValues, initialValues, validationSchema} from './validation';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {ErrorMessage, MutableField} from 'Common/components/StyledComponents/StyledComponents';

import styled from 'styled-components';
import Theme from '../../../../Common/constants/Theme';
import Typography from '../../../../Common/constants/Typography';

import ModalWindow from '../../../../Common/components/Modal/ModalWindow';

import {castToOption, getStringKeysOption} from '../../../../Common/helpers/OptionHelper';
import {SelectOwner} from '../../../../HorseProfile/components/HorseProfileForm/parts';
import {DogGender, Gender} from '../../../../Common/constants/Gender';
import {useDogCreate} from '../../helpers/hooks/useDogActions/useDogCreate';

import {dogBreeds} from 'Common/constants/DogBreeds';
import {dogDisciplines} from 'Common/constants/DogDisciplines';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

interface ExternalProps {
  onSuccess(): void;
}

type OuterProps = ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

export default function DogCreateForm(props: AllProps) {
  const {onSuccess} = props;
  // const {dogTestResultsUploading} = props;
  const createDog = useDogCreate();
  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>Create New Dog</ModalWindowHeader>

      {/*{ <Loading />}*/}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          await createDog({
            name: values.name,
            ownerId: values.userId,
            breed: values.breed,
            gender: values.gender,
            dateOfBirth: values.dateOfBirth ? new Date(values.dateOfBirth).toISOString() : undefined,
            discipline: values.discipline,
          });
          setIsSubmitting(false);
          onSuccess();
        }}
      >
        <Form className="d-flex flex-column justify-content-center">
          <ModalWindowFormContent>
            <TitleContainer>
              <SelectOwner isRequired={true} animalType={'Dog'} />
              <MutableField width={50}>
                <InputField
                  isRequired={true}
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Dogs name"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isMulti={false}
                  name="breed"
                  label="Breeds"
                  options={getStringKeysOption(dogBreeds)}
                  memoized={false}
                  isRequired={true}
                />
              </MutableField>
              <MutableField width={50}>
                <DateTimeField isRequired={false} name="dateOfBirth" label="Date of birth" memoized={true} />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isRequired={true}
                  name="gender"
                  label="Sex"
                  options={getStringKeysOption(DogGender)}
                  memoized={true}
                />
              </MutableField>
              <MutableField width={50}>
                <SelectField
                  isMulti={false}
                  name="discipline"
                  label="Disciplines"
                  options={getStringKeysOption(dogDisciplines)}
                  memoized={false}
                  isRequired={false}
                />
              </MutableField>
            </TitleContainer>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Save
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </Formik>
    </>
  );
}
