import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryPrivacy} from 'OnlineReport/models/Ancestry/IAncestryPrivacy';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryPcaReferencePoint} from 'OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';

const STATE_NAME = 'ancestryReport';

export interface IAncestryHorsePrivacy extends IAncestryPrivacy {
  privateId: string;
}

export interface IAncestryReportState {
  data: {
    population: Nullable<IAncestryPopulation[]>;
    populationComparison: Nullable<IAncestryPopulationComparison>;
    ancestryProbabilities: Nullable<IAncestryProbabilities>;
    ancestryRelatedHorses: IAncestryRelatedHorse[];
    ancestryKinshipHorses: IAncestryKinshipHorse[];
    ancestryHorsePrivacy: IAncestryHorsePrivacy[];
    ancestryPcaReferenceData: IAncestryPcaReferencePoint[];
  };
  communications: {
    populationLoading: ICommunication;
    populationComparisonLoading: ICommunication;
    ancestryProbabilitiesLoading: ICommunication;
    ancestryRelatedHorsesLoading: ICommunication;
    ancestryKinshipHorsesLoading: ICommunication;
    ancestryHorsePrivacyLoading: ICommunication;
    ancestryPcaReferenceDataLoading: ICommunication;
    sendHorsePublicityRequesting: ICommunication;
  };
}

export const getPopulationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_POPULATION');
export type getPopulationActions = MakeCommunicationActions<
  typeof getPopulationActionTypes,
  {success: IAncestryPopulation[]}
>;

export const getPopulationComparisonActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_POPULATION_COMPARISON');
export type getPopulationComparisonActions = MakeCommunicationActions<
  typeof getPopulationComparisonActionTypes,
  {success: IAncestryPopulationComparison}
>;

export const getAncestryProbabilitiesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_PROBABILITIES'
);
export type getAncestryProbabilitiesActions = MakeCommunicationActions<
  typeof getAncestryProbabilitiesActionTypes,
  {success: IAncestryProbabilities}
>;

export const getAncestryRelatedHorsesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_RELATED_HORSES'
);
export type getAncestryRelatedHorsesActions = MakeCommunicationActions<
  typeof getAncestryRelatedHorsesActionTypes,
  {success: IAncestryRelatedHorse[]}
>;

export const getAncestryKinshipHorsesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_KINSHIP_HORSES'
);
export type getAncestryKinshipHorsesActions = MakeCommunicationActions<
  typeof getAncestryKinshipHorsesActionTypes,
  {success: IAncestryKinshipHorse[]}
>;

export const getAncestryHorsePrivacyActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ANCESTRY_HORSE_PRIVACY');
export type getAncestryHorsePrivacyActions = MakeCommunicationActions<
  typeof getAncestryHorsePrivacyActionTypes,
  {success: IAncestryHorsePrivacy}
>;

export const getAncestryPcaReferenceDataActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_PCA_REFERENCE_DATA'
);
export type getAncestryPcaReferenceDataActions = MakeCommunicationActions<
  typeof getAncestryPcaReferenceDataActionTypes,
  {success: IAncestryPcaReferencePoint[]}
>;

export const sendHorsePublicityRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'PUBLICITY_REQUEST');
export type sendHorsePublicityRequestActions = MakeCommunicationActions<
  typeof sendHorsePublicityRequestActionTypes,
  {}
>;

export type AncestryReportActions =
  | getPopulationActions
  | getPopulationComparisonActions
  | getAncestryProbabilitiesActions
  | getAncestryRelatedHorsesActions
  | getAncestryKinshipHorsesActions
  | getAncestryHorsePrivacyActions
  | getAncestryPcaReferenceDataActions
  | sendHorsePublicityRequestActions;
